/* eslint-disable no-console */
import React, { createContext, useContext, useMemo } from 'react'
import * as FS from '@fullstory/browser'
import * as Segment from 'utils/segment'
import {
  Account,
  EmployerStaffAccount,
  EmployerStaffAccountEhiAccessLevel,
  EmployerStaffAccountJobsAccessLevel,
  EmployerStaffAccountRole
} from 'model/organization'
import { useQuery } from '@apollo/client'
import { ENIGMA_CURRENT_ACCOUNT } from 'context/AccountContext/me'
import * as impersonationStorage from 'utils/impersonationStorage'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { getIdentity } from 'utils/authTokenProvider'

export type AccountContextData = {
  data: Account
  isImpersonator: boolean
}

export const INITIAL_DATA: Account = {
  cmsApiKey: '',
  currentAccount: {
    id: '',
    userId: '',
    email: '',
    user: {
      primaryEmail: '',
      id: '',
      name: ''
    },
    employerId: '',
    organizationId: '',
    roles: [],
    ehiAccess: EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS,
    employer: {
      id: '',
      name: '',
      isPortfolio: false
    }
  }
}

export const AccountContext = createContext<AccountContextData>({
  data: INITIAL_DATA,
  isImpersonator: false
})

export const AccountProvider = ({ children }: any): JSX.Element => {
  const isImpersonator = impersonationStorage.isSet()

  const { identify } = useContext(FeatureFlagsContext)

  const { data: enigmaCurrentAccount } = useQuery(ENIGMA_CURRENT_ACCOUNT, {
    onCompleted: (result: any) => {
      const { currentAccount, employerAuthenticationSettings } = result
      identify(currentAccount, employerAuthenticationSettings?.strategy)
      const { id, user, email, employer, roles, jobsAccess, ehiAccess } =
        currentAccount as EmployerStaffAccount

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      FS.isInitialized() &&
        FS.identify(id as string, {
          role_str: roles,
          email,
          displayName: user?.name,
          impersonating_bool: false,
          company_str: employer.name,
          jobAccess_str: jobsAccess,
          ehiAccess_str: ehiAccess
        })

      Segment.identify(id as string, {
        roles,
        email,
        name: user?.name,
        displayName: user?.name,
        impersonating: false,
        company: employer.name,
        jobAccess: jobsAccess,
        ehiAccess
      })
    }
  })

  const { name, email } = getIdentity()

  const value = useMemo(() => {
    const getAccessLevel = (options: any, defaultLevel: any) => {
      const roles = enigmaCurrentAccount?.currentAccount.roles || []
      return roles.find((role: any) => options.includes(role)) || defaultLevel
    }

    // TODO: change isPortfolio is true to false when available
    return {
      data: {
        currentAccount: {
          ...enigmaCurrentAccount?.currentAccount,
          jobsAccess: getAccessLevel(
            Object.values(EmployerStaffAccountJobsAccessLevel),
            EmployerStaffAccountJobsAccessLevel.NO_JOBS_ACCESS
          ),
          ehiAccess: getAccessLevel(
            Object.values(EmployerStaffAccountEhiAccessLevel),
            EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS
          ),

          role: getAccessLevel(
            Object.values(EmployerStaffAccountRole),
            EmployerStaffAccountRole.BASIC_USER
          ),

          isPortfolio:
            enigmaCurrentAccount?.currentAccount?.employer?.isPortfolio ||
            false,

          user: { name, primaryEmail: email }
        },
        cmsApiKey: enigmaCurrentAccount?.cmsApiKey
      },
      isImpersonator
    }
  }, [email, enigmaCurrentAccount, isImpersonator, name])

  return (
    <AccountContext.Provider value={value}>
      {enigmaCurrentAccount?.currentAccount && children}
    </AccountContext.Provider>
  )
}
