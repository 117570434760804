/* eslint-disable no-console */
import React, { useEffect, useContext } from 'react'
import { NotificationContext } from 'context/NotificationContext'
import Client from 'utils/createApolloMaestroClient'
import { loadMicroApp } from 'qiankun'
import { AccountContext } from 'context/AccountContext'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { podiumEntry } from 'utils/entryProvider'
import LoadingIndicator from 'components/LoadingIndicator'

const Podium = () => {
  const { displayNotification } = useContext(NotificationContext)
  const {
    data: { currentAccount, cmsApiKey }
  } = useContext(AccountContext)
  const { flags, isFlagEnabled } = useContext(FeatureFlagsContext)

  useEffect(() => {
    if (!flags) return

    const app = loadMicroApp({
      name: 'podium-portal', // app name registered, same as the one in package.json
      entry: podiumEntry as string,
      container: '#podium-root',
      props: {
        displayNotification,
        currentAccount,
        cmsApiKey,
        maestroClient: Client(),
        isFlagEnabled
      }
    })

    // eslint-disable-next-line consistent-return
    return () => {
      if (app.getStatus() === 'MOUNTED') {
        app.unmount()
      }
    }
    // eslint-disable-next-line
  }, [flags])

  return <LoadingIndicator timeout={3000} />
}

export default Podium
